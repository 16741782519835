<template>
  <section class="content">
    <div class="card card-info">
      <div class="card-header">
        <h3 class="card-title">Daftar Siswa Pengajuan Angsuran</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 d-flex justify-content-end mb-2">
            <div class="row form-horizontal justify-content-end">
                <div class="col-auto col-xs-4">
                    <div class="btn-group" ref="toolbar">
                        <button type="button" data-action="read" class="btn btn-default"><i
                        class="fa fa-sync-alt mr-1"></i>Refresh</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover" ref="tblplanangsuran" id="siswaplan">
            <thead>
              <tr>
                <th>NOMOR</th>
                <th>NAMA SISWA</th>
                <th>TANGGAL</th>
                <th>PEMBAYARAN</th>
                <th>TINDAKAN</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";
export default {
  nama: "Daftar Siswa Pengajuan Angsuran",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      role_id: "",
      submit: "",
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "confirm") {
          this.submit = "approve";
          Swal.fire({
            title: "Konfirmasi?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              authFetch("/pr/siswa_plan_angsuran/" + e.target.dataset.id, {
                method: "PUT",
                body: "submit=" + this.submit,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.tbl.api().ajax.reload();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.tbl.api().ajax.reload();
                  }
                });
            }
          });
        } else if (e.target.dataset.action == "reject") {
          this.submit = "reject";
          Swal.fire({
            title: "Tolak?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              authFetch("/pr/siswa_plan_angsuran/" + e.target.dataset.id, {
                method: "PUT",
                body: "submit=" + this.submit,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.tbl.api().ajax.reload();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.tbl.api().ajax.reload();
                  }
                });
            }
          });
        }
        console.log(e.target);
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    this.tbl = createTable(e.tblplanangsuran, {
      title: "",
      // roles: [""],
      selectedRowClass: "",
      serverSide: true,
      frame: true,
      displayLength: 100,
      lengthChange: false,
      toolbarEl: e.toolbar,
      processing: true,
      rowGroup: {
          dataSrc: 'nama_siswa'
      },
      order: [[2, 'asc']],
      ajax: "/pr/siswa_plan_angsuran",
      columns: [
        { data: "nomor" },
        { data: "nama_siswa"},
        { data: "tanggal"},
        { data: "pembayaran" },
        { data: "pembayaran" },
      ],
      filterBy: [0, 1, 2],
      "order": [[ 1, "asc" ], [ 2, "desc" ]],
      rowCallback: function (row, data) {
        if (data.approval_status == "" || data.approval_status == 0) {
          $("td:eq(4)", row).html(
            `<div class="btn-group">
          <button type="button" class="btn btn-sm btn-outline-info" data-action="confirm" data-id="` +
              data.siswa_registrasi_id +
              `"><i class="fas fa-check"></i> Approve</button>
                    <button type="button" class="btn btn-sm btn-outline-danger" data-action="reject" data-id="` +
              data.siswa_registrasi_id +
              `"><i class="fas fa-times"></i> Reject</button>
                  </div>`
          );
        } else if (data.approval_status == 100) {
          $("td:eq(4)", row).html(
            `<span class="badge badge-danger">REJECT</span>`
          );
        } else if (data.approval_status == 1) {
          $("td:eq(4)", row).html(
            `<span class="badge badge-success">APPROVE</span>`
          );
        }
        let idr = Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: "IDR",
            useGrouping: true,
            maximumSignificantDigits: 3,
        });
        $("td:eq(3)", row).html(
            idr.format(data.pembayaran)
        );
      },
      // "drawCallback": function ( settings ) {
      //       var api = this.api();
      //       var rows = api.rows( {page:'current'} ).nodes();
      //       var last=null;
 
      //       api.column(1, {page:'current'} ).data().each( function ( group, i ) {
      //           if ( last !== group ) {
      //               $(rows).eq( i ).before(
      //                   '<tr class="group"><td colspan="5">'+group+'</td></tr>'
      //               );
 
      //               last = group;
      //           }
      //       } );
      //   }
    });

    $('#siswaplan tbody').on( 'click', 'tr.group', function () {
        var currentOrder = this.tbl.order()[0];
        if ( currentOrder[0] === 1 && currentOrder[1] === 'asc' ) {
            this.tbl.order( [ 1, 'desc' ] ).draw();
        }
        else {
            this.tbl.order( [ 1, 'asc' ] ).draw();
        }
    } );
  },
};
</script>
<style scoped>
</style>